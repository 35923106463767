$baseColorAIB: #AC0000;

.slide-container {
    width: 100%;
    margin: auto; 
    height: 100%;
}
.formatBandeau{
    background-size: 100% 600px !important ;
//    width: 100% !important; 

//font-family: 'Helvetica';

font-size: 3.1em;
color:white;
text-shadow: 1px 1px 3px black;


}

.centerbandeautxt{
    position: relative;
    top: 50%;
    //left: 50%;
    transform: perspective(1px) translateY(-50%);
}

.centerbandeautxt>p{
    text-align: center;
}

h3 {
    text-align: center; }
  
  .each-slide > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 600px;
    width: 100%;

  }

.slider a.previousButton {
    visibility: visible;
}

.slider a.nextButton {
    visibility: visible;
}



  .each-slide span {
    padding: 20px;
    font-size: 20px;
    background: $baseColorAIB;
    
    text-align: center;
  }
  
  .each-fade {
    display: flex;
  }
  
  .each-fade .image-container {
    width: 75%;
    overflow: hidden;
  }
  
  .each-fade .image-container img {
    width: 100%;
  }
  
  .each-fade h2 {
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    background: #adceed;
  }

  .slide.animateIn,
	.slide.animateOut {
	-webkit-transition: all 2s ease;
	transition: all 2s ease;
}

.slide.animateIn.previous,
		.slide.animateIn.next {
	left: 0;
    visibility: visible;
 /*   border-color: red;
    border-style: solid;
    border-width: 1px;
   */ 
}

.slide.animateOut.previous {
	left: 100%;
}

.slide.animateOut.next {
	left: -100%;
}