$baseColorAIB: #AC0000;


/* [class*="col"]{
  border-color: red;
  border-style: solid;
  border-width: 1px;
} */

.footer{
height: 50px;

}
.load{
  opacity: 0;
}
.loadfinish{
  -webkit-transition: all 4s ease;
  transition: all 4s ease;
  opacity: 1;
}

.card-text{
  font-size: 0.9em;
  
}
.txt-titre{
  font-size: 0.9em ;
}
.bloc-bien{

  // border: 1px solid black;
  margin: 0;  /* haut | droit | bas | gauche*/
  height: 620px ;
  
  
}

.card-img-top {
  // border: 1px solid black;
  height: 240px;
  // width: 370px;
}

.overlaymodale {
  
  height: auto;
  width: auto;
  
  
  position: absolute;;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 100;
}
.btn-primary{
  background-color: $baseColorAIB !important;
  border-color: $baseColorAIB !important;
}

.containerAibLight{
  margin: 12px;
  padding: 8px 14px;
  border: 1px solid #D3D3D3;
  box-shadow: 1px 2px 2px #D3D3D3;
  -webkit-box-shadow: 2px 2px 2px #D3D3D3;
  -moz-box-shadow: 2px 2px 2px #D3D3D3;
  background: #D3D3D3;
  background: -webkit-gradient(linear, 0 0, 0 bottom, from(#000000), to(#FBFAF4));
  background: -webkit-linear-gradient(#ffffff, #FBFAF4);
  background: -moz-linear-gradient(#ffffff, #FBFAF4);
  background: -ms-linear-gradient(#ffffff, #FBFAF4);
  background: -o-linear-gradient(#ffffff, #FBFAF4);
  background: linear-gradient(#ffffff, #FBFAF4);
}


.containerAib{
  //background-color: white;
  margin-top: 15px;
  margin-right: 150px;
  margin-bottom: 20px; 
  padding-bottom: 50px;
  border: 1px solid #D3D3D3;
  box-shadow: 1px 2px 2px #D3D3D3;
  -webkit-box-shadow: 2px 2px 2px #D3D3D3;
  -moz-box-shadow: 2px 2px 2px #D3D3D3;
  background: #D3D3D3;
  background: -webkit-gradient(linear, 0 0, 0 bottom, from(#D3D3D3), to(#FBFAF4));
  background: -webkit-linear-gradient(#F1EEDD, #FBFAF4);
  background: -moz-linear-gradient(#F1EEDD, #FBFAF4);
  background: -ms-linear-gradient(#F1EEDD, #FBFAF4);
  background: -o-linear-gradient(#F1EEDD, #FBFAF4);
  background: linear-gradient(#F1EEDD, #FBFAF4);  
}

a.menuSelected{
  color: white !important;
  background-color:$baseColorAIB;
  text-shadow: 2px 2px 2px #333333;
  
}
a.menuAib{
  color: black;
  border-left: 0.5px solid black;
  //border-right: 0.5px solid black;
  text-decoration: none !important;
  padding: 20px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar-default {
  border-color: black;
  border: 0px;
  background-color: transparent;
  box-shadow: 1px 1px 1px grey;
  -webkit-box-shadow: 1px 1px 1px grey;
  -moz-box-shadow: 1px 1px 1px grey;
}

.navbar-default .navbar-nav>li>a {
  color: black;
  //font-family:  KGAlwaysAGoodTime;
  //font-family:  Calibri;
  font-family: 'Helvetica';
  font-size: 1.2em;
}

.navbar-default .navbar-nav>li>a:hover,
.navbar-default .navbar-nav>li>a:focus {
  color: white;
  background-color: $baseColorAIB;
  text-shadow: 2px 2px 2px #333333;
}