    /*
 * This is a manifest file that'll be compiled into application.css, which will include all the files
 * listed below.
 *
 * Any CSS and SCSS file within this directory, lib/assets/stylesheets, vendor/assets/stylesheets,
 * or any plugin's vendor/assets/stylesheets directory can be referenced here using a relative path.
 *
 * You're free to add application-wide styles to this file and they'll appear at the bottom of the
 * compiled file so the styles you add here take precedence over styles defined in any styles
 * defined in the other CSS/SCSS files in this directory. It is generally better to create a new
 * file per style scope.
 *
 *= require bootstrapValidator.min
 *= require jquery-ui

 *= require_tree .
 *= require_self
 */

  //  @import "bootstrap-sprockets";
  //  @import "bootstrap";
  @import url(https://fonts.googleapis.com/css?family=Kalam);
  @import url(https://fonts.googleapis.com/css?family=Roboto);

  @import url(https://unpkg.com/leaflet@1.6.0/dist/leaflet.css);
  @import "~react-image-gallery/styles/scss/image-gallery.scss";



  $baseColorAIB: #AC0000;



  .centerContainer{
    display:flexbox;
    border-color: red;
    border-style: solid;
    border-width: 1px;
    
  }

  .centerVisiteVirtuelle{
    margin: auto;
    padding: auto;
    position: relative;
    display: flex;
    top: 5%;
    left: 0%;

  }
  .visitevirtuelletxt {
    margin-top: 20px;
    text-align: center;
    color: #AC0000;
    font-family: "Roboto";
    font-weight: bold;
    font-size: 1.1em;
    display: block;
    font-style: italic;
}
  .centerVisiteVirtuelleInside{
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        border-radius: 2px;
        box-shadow: 2px 2px 8px #333333;
        -webkit-box-shadow: 2px 2px 8px #333333;
        -moz-box-shadow: 2px 2px 8px #333333;

  }
  
  .decaleTxtDroite{
      padding:1.5em;
      // text-indent:2em;
  }

  .decaleTxtDroitePlus{
      padding:2.5em;
      // text-indent:2em;
  }
  .textAccueil1{
      font-size: 1.8em;
      // font-size: large;
      text-align: center;
      font-family: 'Kalam';
      // font-size: 1.1em;
      color: #AC0000;
      text-shadow: 1px 1px 3px #333333;

  }

  .textAccueil2{
      font-size: 1.6em;
      text-align: center;
      font-family: 'Kalam';
      text-shadow: 1px 1px 2px #333333;
  }
  .textAccueil3{
      font-size: 1.4em;
      text-align: center;
      font-family: 'Kalam';
      text-shadow: 1px 1px 2px #333333;
  }
  .textAccueil4{
      font-size: 1.2em;
      text-align: center;
      font-family: 'Kalam';
      text-shadow: 1px 1px 2px #333333;
  }
  .textAccueil4{

      font-size: 1.2em;
      text-align: center;
      text-shadow: 1px 1px 2px #333333;

  }


.zoomEffect, .zoomEffect:focus {
    opacity: 1;
  
    box-shadow: 2px 2px 8px #333333;
    -webkit-box-shadow: 2px 2px 8px #333333;
    -moz-box-shadow: 2px 2px 8px #333333
  }
  
  .zoomEffect:hover {
   transform: scale(1.1);
   opacity: 1;
  }

 .vertCenter{
    display: flex;
    align-items: flex-start;  
    justify-content: center;
    gap: 16px;
}
.posImg{
    width: 120px;
    height: 120px;
    object-fit: cover;
}
.containerImg{
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* espace entre les images */
  
}
@media screen and (max-width: 768px) {
    .containerImg {
        flex-direction: column;
    }
    
    .posImg {
        width: 100%; /* Les images prennent toute la largeur disponible */
        max-width: 120px; /* mais pas plus que 120px */
    }
}

  .colorAIB{
      color: $baseColorAIB;
  }

  .displaydessous{
      display: block;
      // margin-bottom: 10px;
  }


  .displaydessousChargeHonoraires{
      display: block;
      margin-bottom: 8px;
      font-size: 0.7em;
      font-style: italic;
  }

  .mandatory {
          position: absolute;
          margin-left: -10px;
          color: $baseColorAIB;
          font-size: 15px;



  }
  .alignLeft{

      display: inline-block;
      font-size: 0.9em;
      padding: 5px;
  }
  .alignLeftcoutannuel{

      display: inline-block;
      font-size: 1.1em;
      margin: 2px;
  }

  .resizeImgBackground{
      background-size: contain !important;
  }

.popupCodiv{
  display: flex;
  position: absolute;
 // opacity: 0.8;
  // border: 1px solid red;

  width: 700px;
  height: 320px;
  z-index: 1000;
  background-color: #fff;
  // top: 50%;
  top: 270px;
  left: 50%;
  transform: translate(-50%,0%);
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;

  box-shadow: 4px 4px 8px #333333;
  -webkit-box-shadow: 4px 4px 8px #333333;
  -moz-box-shadow: 4px 4px 8px #333333;

}



.txtCodid{
  padding: 50px;
  color: $baseColorAIB;
  font-size: 1.4rem;
  text-shadow: none;
  text-shadow: 1px 1px 3px black;
  text-shadow: 1px 1px 3px white;
  text-align: center;
}

.txtCodid1{
  font-size: 1.8rem;

}

.txtCodid2{
  font-size: 0.8em;
  color: black;
}
.txtCodid3{
  font-size: 0.7em;

}
.txtCodid4{
  font-size: 0.7em;

}

//  @import "font-awesome-sprockets";
//  @import "font-awesome";
.slide-container {
  //width: 300px !important;
  height: 200px !important;
  //margin: auto !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  margin-right: 20px !important;

}

.slide-container a.previousButton {
  top: 100px !important;
  left: 2px !important;
  font-size: 18px !important;
}
.slide-container a.nextButton {
  top: 100px !important;
  right: 2px !important;
  font-size: 18px !important;
}


.showSlider{
    margin-right: 20px;
  //   box-shadow: 4px 10px 8px #333333;
  //   -webkit-box-shadow: 4px 10px 8px #333333;
  //   -moz-box-shadow: 4px 10px 8px #333333;

    // box-shadow: 2px 2px 2px #333333;
    // -webkit-box-shadow: 2px 2px 2px #333333;
    // -moz-box-shadow: 2px 2px 4px #333333;

    

    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 2px;
    box-shadow: 2px 2px 8px #333333;
    -webkit-box-shadow: 2px 2px 8px #333333;
    -moz-box-shadow: 2px 2px 8px #333333;
    // border-color: red;
    // border-style: solid;
    // border-width: 3px;
}

.addpaddingSlider{

   margin-bottom: 20px;
  
//   box-shadow: 4px 4px 8px #333333;
//   -webkit-box-shadow: 4px 4px 8px #333333;
//   -moz-box-shadow: 4px 4px 8px #333333;

//   box-shadow: 2px 2px 2px #333333;
//       -webkit-box-shadow: 2px 2px 2px #333333;
//       -moz-box-shadow: 2px 2px 4px #333333;
border: 0px;
}

.addpaddingSlider:hover{
   cursor: zoom-in;
 }


.sliderOverwrite{

  // width: 50%  !important;
   height: 200px !important;
   //height: 400px !important;
   background-size: cover !important; /* version standardisée */


 }
.aibRedColor{
    color: #AC0000;
}

body{
  font-family: 'Roboto';
}


.leaflet-container {
  width: 100%;
  height: 100vh;
}

.addmarginRight{
    margin-right: 20px;;
}
.sct{
  border-color: red;
  border-style: solid;
  border-width: 3px;

}

.reducemargintop{
  margin-top: 0px !important;
}
.reducemarginright{
  margin-right: -40px !important;
  padding-right: 0px !important;
}


.addmarginright{
  margin-left:  100px !important;
  padding-right: 0px !important;
}

.with-errors{
  color: red;
  font-style: italic;
  font-size: 0.8em;

}

.white{
  background-color: white;

}

.divaibblockAccueilLogo {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-left: 50px;
  padding-right: 50px;
  margin-top: 15px;
  margin-bottom: 50px;
  border: 1px solid #D3D3D3;
  box-shadow: 1px 2px 2px #D3D3D3;
  -webkit-box-shadow: 2px 2px 2px #D3D3D3;
  -moz-box-shadow: 2px 2px 2px #D3D3D3;
  background: #D3D3D3;
  background: -webkit-gradient(linear, 0 0, 0 bottom, from(#D3D3D3), to(#FBFAF4));
  background: -webkit-linear-gradient(#F1EEDD, #FBFAF4);
  background: -moz-linear-gradient(#F1EEDD, #FBFAF4);
  background: -ms-linear-gradient(#F1EEDD, #FBFAF4);
  background: -o-linear-gradient(#F1EEDD, #FBFAF4);
  background: linear-gradient(#F1EEDD, #FBFAF4);
  padding: 30px;
}

.imgAccueil{

  // border: 1px solid black;
  // margin-top: 30px;
   margin-bottom: 40px;
  // margin-right: 30px;

  //padding: 20px;
  //margin: auto;
}

.imgAccueil10{
  width: 160px;

}
.imgAccueil11{
  // border: 1px solid black;
  width: 160px;

}

.honorairebleu{
  color: #0000CC;
}
.imgAccueil1{
  //border: 1px solid black;
  margin-left: 30px;
  width: 80%;
  -webkit-transform: rotate(-10deg);
  transform: rotate(-10deg);
  }

.titrevitrineBandeau::before,
.titrevitrineBandeau::after {
  display: inline-block;
  content: "";
  border-top: 2px solid #AC0000;
  width: 5%;
  margin: 0 1rem;
  transform: translateY(-6px);
}
.titrevitrineBandeau {

  text-align: center;
  //background: #AC0000;
//    color: white;
  color: #AC0000;
  word-spacing: 0.2em;
//font-family: 'Helvetica';
  font-family: 'Kalam';
  font-size: 1.1em;
  text-shadow: 1px 1px 2px #333333;

}



.titrevitrine {
  
  position:relative;
  top: 50%;
  
  //background: #AC0000;
  border: 1px solid white;
  // margin: 5px;
  text-align: center;
  // font-size: medium;
  font-size: 0.9em;
  color: $baseColorAIB;
  
  //bottom: 0%;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  background-color: #fff;
  
  
  opacity: 0.7;

}


.containerTextHomepage{
  margin-top: 50px;

}



.divaibblockTextHomepage {

  padding-left: 50px;
  padding-right: 50px;

  border: 1px solid #D3D3D3;
  box-shadow: 1px 2px 2px #D3D3D3;
  -webkit-box-shadow: 2px 2px 2px #D3D3D3;
  -moz-box-shadow: 2px 2px 2px #D3D3D3;
  background: #D3D3D3;
  background: -webkit-gradient(linear, 0 0, 0 bottom, from(#D3D3D3), to(#F1EEDD));
  background: -webkit-linear-gradient(#F1EEDD, #FBFAF4);
  background: -moz-linear-gradient(#F1EEDD, #FBFAF4);
  background: -ms-linear-gradient(#F1EEDD, #FBFAF4);
  background: -o-linear-gradient(#F1EEDD, #FBFAF4);
  background: linear-gradient(#F1EEDD, #FBFAF4);

}


@keyframes delayedShow{
  from  {
      opacity: 0.2;

  }
  to {
      opacity: 0.8;
  }



}
.visiter{

animation:  delayedShow 2s;

   // display: block;
    position: relative;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);

    opacity: 0.8;
    box-shadow: 4px 10px 8px #333333;
    -webkit-box-shadow: 4px 10px 8px #333333;
    -moz-box-shadow: 4px 10px 8px #333333;

}

.reduceSpace{
  margin: 120px;


}



.imgPartenaire {

    margin-bottom: 50px;
    width: 200px;



}
/* Rotation */
.rotate div img {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.rotate div:hover img {
  -webkit-transform: rotate(-15deg);
  transform: rotate(-15deg);
}

.rotate-zoom-out div img {
  -webkit-transform: rotate(10deg) scale(1.25);
  transform: rotate(10deg) scale(1.25);
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
}
.rotate-zoom-out div:hover img {
  -webkit-transform: rotate(0) scale(1);
  transform: rotate(0) scale(1);
}

.addpadding2{
  padding-bottom: 25px;

}
.addpadding3{
  padding-bottom: 25px;
  padding-top: 50px;

}
.addpadding1{
  //padding-top: 20px;
  //padding-bottom: 10px;
  font-size: larger;
  box-shadow: 4px 10px 8px #333333;
   -webkit-box-shadow: 4px 10px 8px #333333;
   -moz-box-shadow: 4px 10px 8px #333333;
}

.pos{
  padding: 10px;
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.wrapper1 {
  z-index: 101;
  text-align: center;
  vertical-align: middle ;
/*    height: 200px;
  width: 700px;
  background: rgb(190, 186, 186);
    */

  position: absolute;
  top: 400px;
  left: 50%;
  transform: translate(-50%, -50%);

  /*border-color: red;
  border-style: solid;
  border-width: 1px;
  */
  padding: 50px;

}
.overlay1{

  z-index: 100;
  //background: rgba(163, 161, 161, 1);
  background-image: url('../images/bodybg.png');
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: -500px;
  height: 15000px;


}


.overlay{
  background: rgba(163, 161, 161, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: -500px;

}
.addpadding{
  padding-top: 20px;
  padding-bottom: 10px;
  font-size: larger;

}
.wrapper {
  text-align: center;
  vertical-align: middle ;
  height: 200px;
  width: 700px;
  background: rgb(190, 186, 186);
  position: absolute;
  top: 800px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.txtCenterHonoraires{
    
    font-size: 0.8em;
    text-shadow: none;
    text-align: center;
    vertical-align: middle ;
  }
.hrHonoraires {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;

    
}  

.centerTxt{

  text-align: center;
  vertical-align: middle ;

}
.pLeft{
  text-align: left;
}
.mail{
  text-decoration: none !important;
  color: black;
}


  @font-face {
      font-family: 'SwaggerBold';
      font-weight: normal;
      font-style: bold;
      // src: font-url('fonts/SwaggerBold.otf');
     src: font-url('fonts/SwaggerBold.ttf');
  }

  @font-face {
      font-family: 'Bringshoot';
      font-weight: normal;
      font-style: normal;
      src: font-url('fonts/Bringshoot.otf');
      src: font-url('fonts/Bringshoot.ttf');
  }

  @font-face {
      font-family: 'simplejoys';
      font-weight: normal;
      font-style: normal;
      src: font-url('fonts/simplejoys.ttf');
  }

  @font-face {
      font-family: 'KGAlwaysAGoodTime';
      font-weight: normal;
      font-style: normal;
      src: font-url('fonts/KGAlwaysAGoodTime.ttf');
  }

  .securecode{
      margin-left: 20px;
      font-weight: bold;
      font-style: italic;
      border-style: dashed;
      color: #AC0000;
      border-color: #AC0000;
      padding:5px;
  }

  .border_red {
      border: 1px;
      //border-style: solid;
      border-style: dashed;
      border-color: #AC0000;
  }

  .honoraires {
      font-style: italic;
      font-size: 0.675em !important;
  }



.buttonInfo, .buttonInfo:focus {
   opacity: 0.8;
   box-shadow: 4px 10px 8px #333333;
   -webkit-box-shadow: 4px 10px 8px #333333;
   -moz-box-shadow: 4px 10px 8px #333333
}

.buttonInfo:hover {
  transform: scale(1.1);
  opacity: 1;
}


.meilleursAgents, .meilleursAgents:focus {
  opacity: 0.8;

  box-shadow: 2px 2px 8px #333333;
  -webkit-box-shadow: 2px 2px 8px #333333;
  -moz-box-shadow: 2px 2px 8px #333333
}

.meilleursAgents:hover {
 transform: scale(1.1);
 opacity: 1;
}



  .dateupdate {
      font-style: italic;
      font-size: 0.875em !important;
  }

  .activenavclicked {
      color: grey;
      background-color: #AC0000 !important;
  }

  .errorval {
      color: red;
  }

  .navbar-default {
      border-color: black;
      border: 0px;
      background-color: transparent;
      box-shadow: 1px 1px 1px grey;
      -webkit-box-shadow: 1px 1px 1px grey;
      -moz-box-shadow: 1px 1px 1px grey;
  }

  .navbar-default .navbar-nav>li>a {
      color: black;
      //font-family:  KGAlwaysAGoodTime;
      //font-family:  Calibri;
      font-family: 'Helvetica';
      font-size: 1.2em;
  }

  .navbar-default .navbar-nav>li>a:hover,
  .navbar-default .navbar-nav>li>a:focus {
      color: white;
      background-color: #AC0000;
      text-shadow: 2px 2px 2px #333333;
  }

  .bgcontainer {
      background-image: url('../images/bodybg.png');

  }

  .bgmain {
      background-color: white;
      margin-top: 20px;
      box-shadow: 5px 5px 5px #333333;
      -webkit-box-shadow: 5px 5px 5px #333333;
      -moz-box-shadow: 5px 5px 5px #333333;
      padding-bottom: 10px;

  }

  .bandeau {
      box-shadow: -4px 10px 8px #333333;
      -webkit-box-shadow: -4px 10px 8px #333333;
      -moz-box-shadow: -4px 10px 8px #333333;
  }

  .policeHandWriting {
      //font-family:  Bringshoot;
      //font-family:  simplejoys;
      font-family: KGAlwaysAGoodTime;
      //font-weight: 900;
  }

  .policeAIB {
      font-family: Cosmic;
  }

  .blockAIB {
      display: block;
      background-color: #AC0000;
      float: left;
      width: 10%;
      margin-right: 10px;
  }

  .blockAIB1 {
      display: block;
      background-color: #AC0000;
      float: right;
      width: 50%;
  }

  .breadcrumb {
      background: #AC0000;
      border: 0px solid grey;
      border-radius: 4px;
      display: block;
      box-shadow: 1px 1px 1px grey;
      -webkit-box-shadow: 1px 1px 1px grey;
      -moz-box-shadow: 1px 1px 1px grey;
      text-shadow: 2px 2px 2px #333333;

  }

  .breadcrumb li {
      font-size: 0.9em;
      color: white;
      font-family: 'Helvetica';
  }

  .breadcrumb a {
      color: white;
  }

  .breadcrumb a:hover {
      color: grey;
  }

  .breadcrumb>.active {
      color: white;
  }

  .breadcrumb>li+li:before {
      color: white;
      content: "\00bb\00a0";
  }

  p {
      //font-family: 'Helvetica';
      font-family: 'Roboto';

  }

  .footer {
      // border-style: solid;
      // border-width: 1px;
      margin-top: 20px;
      font-size: 12px;
      color: #AC0000;
      text-align: center;
      // text-shadow: 1px 1px 1px #333333;
      text-shadow: 1px 1px 1px #aaa;
  }
  .datetimesiteupdated {
      // border-style: solid;
      // border-width: 1px;
      margin-top: auto;
      font-size: 0.7em;
      color: #AC0000;
      text-align: right;
      text-shadow: 1px 1px 1px #aaa;
  }

  .marginimg {
      margin-top: 15px;
      margin-bottom: 15px;
  }

  @media all and (min-width: 1100px) {
      .aib-center {
          text-align: center;
          //font-family: 'Helvetica';
          font-family: 'Kalam';
          font-size: 1.1em;
          color: #AC0000;
          text-shadow: 1px 1px 3px #333333;
      }
      .aib-center-neutre {
          text-align: center;
          font-family: 'Kalam';
          font-size: 1.1em;
      }
      .aib-agence {
          font-size: 1.2em;
      }
      .divaibblock {
          //background-color: white;
          margin-top: 15px;
          margin-right: 150px;
          margin-left: 150px;
          margin-bottom: 150px;
          border: 1px solid #D3D3D3;
          box-shadow: 1px 2px 2px #D3D3D3;
          -webkit-box-shadow: 2px 2px 2px #D3D3D3;
          -moz-box-shadow: 2px 2px 2px #D3D3D3;
          background: #D3D3D3;
          background: -webkit-gradient(linear, 0 0, 0 bottom, from(#D3D3D3), to(#FBFAF4));
          background: -webkit-linear-gradient(#F1EEDD, #FBFAF4);
          background: -moz-linear-gradient(#F1EEDD, #FBFAF4);
          background: -ms-linear-gradient(#F1EEDD, #FBFAF4);
          background: -o-linear-gradient(#F1EEDD, #FBFAF4);
          background: linear-gradient(#F1EEDD, #FBFAF4);
      }
  }

  .divaibblock2 {
      border: 1px solid #D3D3D3;

      padding-bottom: 10px;
      font-family: 'Roboto';
      font-size: 0.8em;

  }


  .addpadding{
      padding: 15px;
      font-size: 0.9em;
  }

  .divaibblock1 {
      //background-color: white;
      padding-bottom: 15px;
      border: 1px solid #D3D3D3;
      box-shadow: 1px 2px 2px #D3D3D3;
      -webkit-box-shadow: 2px 2px 2px #D3D3D3;
      -moz-box-shadow: 2px 2px 2px #D3D3D3;
      background: #D3D3D3;
      margin-bottom: 15px;
      background: -webkit-gradient(linear, 0 0, 0 bottom, from(#D3D3D3), to(#FBFAF4));
      background: -webkit-linear-gradient(#F1EEDD, #FBFAF4);
      background: -moz-linear-gradient(#F1EEDD, #FBFAF4);
      background: -ms-linear-gradient(#F1EEDD, #FBFAF4);
      background: -o-linear-gradient(#F1EEDD, #FBFAF4);
      background: linear-gradient(#F1EEDD, #FBFAF4);
  }

  .divaibblock1:hover {
      background: -webkit-gradient(linear, 0 0, 0 bottom, from(#000000), to(#FBFAF4));
      background: -webkit-linear-gradient(#ffffff, #E4E4E4);
      background: -moz-linear-gradient(#ffffff, #E4E4E4);
      background: -ms-linear-gradient(#ffffff, #E4E4E4);
      background: -o-linear-gradient(#ffffff, #E4E4E4);
      background: linear-gradient(#ffffff, #E4E4E4);
      //border-top: 10px solid #AC0000;
  }

  .titreaib {
      background: #AC0000;
      border-bottom: 1px solid #D3D3D3;
      font-size: 1.3em;
      text-align: center;
      color: white;


  }
  .titreaibmsg{
      background: #AC0000;
      border-bottom: 1px solid #D3D3D3;
      font-size: 1.3em;
      text-align: center;
      color: white;
      min-height: 150px;
  }
  .titreaibmsgQRCode{
    background: #AC0000;
    border-bottom: 1px solid #D3D3D3;
    font-size: 1.3em;
    text-align: center;
    color: white;
    // min-height: 150px;
}


.centerQRCode{
    
    /* Center vertically and horizontally */
    position: relative;
    display: flex;

    top: 50%;
    left: 50%;
    margin: auto; /* Apply negative top and left margins to truly center the element */
}


  .aib-color {
      color: #AC0000;
  }

  .sizeplus {
      font-size: large;
  }

  .txtcoloraib {
      color: #AC0000;
      font-family: arial;
  }

  .prix {
      text-align: center;
      color: #AC0000;
      //font-family: arial;
      font-family: "Roboto";
      font-weight: bold;
      //font-size: large;
      font-size: 1.5em;
      display: block;
      margin-top: 20px;
  }
  .dpegesnonsousmis{
color: #AC0000;
      font-family: "Roboto";
      font-weight: bold;
      font-size: 1.1em;
      display: block;
      
  }
  .honorairesDetails {
      text-align: center;
      color: #AC0000;
      font-family: "Roboto";
      font-weight: bold;
      font-size: 1.1em;
      display: block;
      font-style: italic;
  }

  .coupcoeur {
      color: #A82626;
  }

  .couleurAIB {
      color: #AC0000;
  }
  .leaflet-div-icon {
      border : none !important;
  }

  .red-error{
      color: #AC0000;
      font-weight: bold;
      font-style: italic;
  }

  .hyperlinkempty:hover{
      color: #AC0000;
      text-decoration:underline;
      cursor: pointer;
  }

  .addcursor:hover{
      cursor: pointer;
  }

  a:hover {
      color: #AC0000;
      text-decoration:underline;
      cursor: pointer;
  }

  input, label {
      display:block;
  }

  .txttitre {
      //font-size: 12px;
      font-size: 1.1em;
      color: #AC0000;
      margin-top: 5px;
      text-shadow: 1px 1px 2px #333333;
  }

  .txttitre1 {
      //font-size: 12px;
      text-align: center;
      font-size: 1.8em;
      color: #AC0000;
      margin-top: 5px;
      text-shadow: 1px 1px 2px #333333;
  }

  #map {
      height: 100%;
  }

  .blanc {
      background-color: white;
      margin: auto;
      margin-top: 10px;
      margin-bottom: 5px;
      height: 320px;
      box-shadow: 1px 2px 2px black;
      -webkit-box-shadow: 2px 2px 2px black;
      -moz-box-shadow: 2px 2px 2px black;
  }

  .blanc:hover {
      background-color: white;
      margin: auto;
      margin-top: 10px;
      margin-bottom: 5px;
      height: 320px;
      box-shadow: 1px 2px 2px #AC0000;
      -webkit-box-shadow: 2px 2px 2px #AC0000;
      -moz-box-shadow: 2px 2px 2px #AC0000;
  }

  .centerimg {
      position: absolute;
      top: 50%;
      /* poussé de la moitié de hauteur du référent */
      transform: translateY(-50%);
      /* tiré de la moitié de sa propre hauteur */
      margin-left: 40px;
  }

  .border1px {
      border: 1px solid green;
  }

  .img-rounded {
      border: 1px solid green;
  }

  .aibblock {
      //margin-right: 50px;
      //margin-left: 50px;
      margin: 0 auto;
  }

  .footerdct {
      height: 50px;
  }

  .row {
      margin-top: 10px;

  }

  .popover-title {
      background-color: #f7f7f7;
      border-bottom: 1px solid #ebebeb;
      border-radius: 5px 5px 0 0;
      color: #000000;
      font-size: 14px;
      font-weight: normal;
      line-height: 18px;
      margin: 0;
      padding: 8px 14px;
  }
  /* popover-content est à modifier pour le contenu de la popover */

  .popover-content {
      background-color: #f7f7f7;
      border-bottom: 1px solid #ebebeb;
      border-radius: 5px 5px 0 0;
      color: #000000;
      font-size: 14px;
      font-weight: normal;
      line-height: 18px;
      margin: 0;
      padding: 8px 14px;
  }

  .notifmsg {
      margin: auto;
      height: 50px;
  }

  .above {
      display: block;
  }

  .border1 {
      margin: 12px;
      padding: 8px 14px;
      border: 1px solid #D3D3D3;
      box-shadow: 1px 2px 2px #D3D3D3;
      -webkit-box-shadow: 2px 2px 2px #D3D3D3;
      -moz-box-shadow: 2px 2px 2px #D3D3D3;
      background: #D3D3D3;
      background: -webkit-gradient(linear, 0 0, 0 bottom, from(#000000), to(#FBFAF4));
      background: -webkit-linear-gradient(#ffffff, #FBFAF4);
      background: -moz-linear-gradient(#ffffff, #FBFAF4);
      background: -ms-linear-gradient(#ffffff, #FBFAF4);
      background: -o-linear-gradient(#ffffff, #FBFAF4);
      background: linear-gradient(#ffffff, #FBFAF4);
  }

  .carousel-control.left,
  .carousel-control.right {
      background: none !important;
      outline: 0;
  }

  .carousel .carousel-control {
      visibility: hidden;
  }

  .carousel:hover .carousel-control {
      visibility: visible;
  }

  .carousel {
      border: 1px solid #D3D3D3;
      box-shadow: 1px 2px 2px #D3D3D3;
      -webkit-box-shadow: 2px 2px 2px #D3D3D3;
      -moz-box-shadow: 2px 2px 2px #D3D3D3;
  }
  /*
div {
border: 1px solid green;
}
*/

.dpeUnknown {

      /* takes the 'cap' out off flow */

  z-index: 100;
  font-size: 0.9em;
  background: #AC0000 none repeat scroll 0 0;
  color: #fff;
  //left: -87px;
  left: -20px;
  padding-bottom: 5px;
  padding-top: 5px;
  position: absolute;
  text-align: center;
  //top: 0px;
  top: 60px;
  transform: rotateZ(-25deg);
  transform-origin: right top 0;
  //width: 200px;
  width: 250px;
  opacity: 0.8;
  -moz-box-shadow: 2px 2px 3px #656565;
  -webkit-box-shadow: 2px 2px 3px #656565;
  -o-box-shadow: 2px 2px 2px #656565;
  box-shadow: 2px 2px 3px #656565;

  filter: "progid: DXImageTransform.Microsoft.Shadow(color='#6565', Direction=135, Strength=5)";
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
}

  .exclusivitebandeau {
      z-index: 100;
      background: #AC0000 none repeat scroll 0 0;
      color: #fff;
      //left: -87px;
      left: -20px;
      padding-bottom: 5px;
      padding-top: 5px;
      position: absolute;
      text-align: center;
      //top: 0px;
      top: 10px;
      transform: rotateZ(-35deg);
      transform-origin: right top 0;
      width: 150px;
      //width: 300px;
      opacity: 0.8;
      -moz-box-shadow: 10px 10px 5px #656565;
      -webkit-box-shadow: 10px 10px 5px #656565;
      -o-box-shadow: 10px 10px 5px #656565;
      box-shadow: 10px 10px 5px #656565;
      filter: "progid: DXImageTransform.Microsoft.Shadow(color='#6565', Direction=135, Strength=5)";
      -moz-border-radius: 6px;
      -webkit-border-radius: 6px;
      border-radius: 6px;
  }

  .DPE_A:before {
      margin-left: 10px;
      padding-top: 5px;
      content: "<50";
      text-align: center;
      vertical-align: middle;
      font-weight: bold;
  }

  .DPE_A {
      width: 15%;
      height: 30px;
      background-color: #339A33;
      margin: 1px 5px;
      position: relative;
  }

  .DPE_A>span {
      float: right;
      vertical-align: middle;
      font-weight: bold;
      margin: 0;
      padding: 5px;
  }

  .DPE_B>span {
      float: right;
      vertical-align: middle;
      font-weight: bold;
      margin: 0;
      padding: 5px;
  }

  .DPE_C>span {
      float: right;
      vertical-align: middle;
      font-weight: bold;
      margin: 0;
      padding: 5px;
  }

  .DPE_D>span {
      float: right;
      vertical-align: middle;
      font-weight: bold;
      margin: 0;
      padding: 5px;
  }

  .DPE_E>span {
      float: right;
      vertical-align: middle;
      font-weight: bold;
      margin: 0;
      padding: 5px;
  }

  .DPE_F>span {
      float: right;
      vertical-align: middle;
      font-weight: bold;
      margin: 0;
      padding: 5px;
  }

  .DPE_G>span {
      float: right;
      vertical-align: middle;
      font-weight: bold;
      margin: 0;
      padding: 5px;
  }

  .DPE_A:after {
      content: "";
      /* required */
      position: absolute;
      /* takes the 'cap' out off flow */
      top: 0;
      /* stick it to top edge of the sign */
      left: 100%;
      /* push it way overto the right*/
      height: 0;
      /* we're doing this with borders remember */
      width: 0;
      border-width: 15px;
      border-style: solid;
      border-color: #339A33;
      /* same as bg of our rectangle */
      /* now we make some of theborders disappear*/
      border-top-color: transparent;
      border-bottom-color: transparent;
      border-right-color: transparent;
  }

  .DPE_B:before {
      font-weight: bold;
      padding-top: 5px;
      margin-left: 10px;
      content: "51 à 90";
      text-align: center;
      vertical-align: middle;
  }

  .DPE_B {
      // 51 à 90
      width: 25%;
      height: 30px;
      background-color: #33CC33;
      margin: 1px 5px;
      position: relative;
  }

  .DPE_B:after {
      content: "";
      /* required */
      position: absolute;
      /* takes the 'cap' out off flow */
      top: 0;
      /* stick it to top edge of the sign */
      left: 100%;
      /* push it way overto the right*/
      height: 0;
      /* we're doing this with borders remember */
      width: 0;
      border-width: 15px;
      border-style: solid;
      border-color: #33CC33;
      /* same as bg of our rectangle */
      /* now we make some of theborders disappear*/
      border-top-color: transparent;
      border-bottom-color: transparent;
      border-right-color: transparent;
  }

  .DPE_C:before {
      padding-top: 5px;
      font-weight: bold;
      margin-left: 10px;
      content: "91 à 150";
      text-align: center;
      vertical-align: middle;
  }

  .DPE_C {
      // 91 à 150
      width: 35%;
      height: 30px;
      background-color: #CCFF33;
      margin: 1px 5px;
      position: relative;
  }

  .DPE_C:after {
      content: "";
      /* required */
      position: absolute;
      /* takes the 'cap' out off flow */
      top: 0;
      /* stick it to top edge of the sign */
      left: 100%;
      /* push it way overto the right*/
      height: 0;
      /* we're doing this with borders remember */
      width: 0;
      border-width: 15px;
      border-style: solid;
      border-color: #CCFF33;
      /* same as bg of our rectangle */
      /* now we make some of theborders disappear*/
      border-top-color: transparent;
      border-bottom-color: transparent;
      border-right-color: transparent;
  }

  .DPE_D:before {
      font-weight: bold;
      padding-top: 5px;
      margin-left: 10px;
      content: "151 à 230";
      text-align: center;
      vertical-align: middle;
  }

  .DPE_D {
      // 151 à 230
      width: 45%;
      height: 30px;
      background-color: #FFFF00;
      margin: 1px 5px;
      position: relative;
  }

  .DPE_D:after {
      content: "";
      /* required */
      position: absolute;
      /* takes the 'cap' out off flow */
      top: 0;
      /* stick it to top edge of the sign */
      left: 100%;
      /* push it way overto the right*/
      height: 0;
      /* we're doing this with borders remember */
      width: 0;
      border-width: 15px;
      border-style: solid;
      border-color: #FFFF00;
      /* same as bg of our rectangle */
      /* now we make some of theborders disappear*/
      border-top-color: transparent;
      border-bottom-color: transparent;
      border-right-color: transparent;
  }

  .DPE_E:before {
      font-weight: bold;
      padding-top: 5px;
      margin-left: 10px;
      content: "231 à 330";
      text-align: center;
      vertical-align: middle;
  }

  .DPE_E {
      // 231 à 330
      width: 55%;
      height: 30px;
      background-color: #FFCC00;
      margin: 1px 5px;
      position: relative;
  }

  .DPE_E:after {
      content: "";
      /* required */
      position: absolute;
      /* takes the 'cap' out off flow */
      top: 0;
      /* stick it to top edge of the sign */
      left: 100%;
      /* push it way overto the right*/
      height: 0;
      /* we're doing this with borders remember */
      width: 0;
      border-width: 15px;
      border-style: solid;
      border-color: #FFCC00;
      /* same as bg of our rectangle */
      /* now we make some of theborders disappear*/
      border-top-color: transparent;
      border-bottom-color: transparent;
      border-right-color: transparent;
  }

  .DPE_F:before {
      font-weight: bold;
      padding-top: 5px;
      margin-left: 10px;
      content: "331 à 450";
      text-align: center;
      vertical-align: middle;
  }

  .DPE_F {
      // 331 à 450
      width: 65%;
      height: 30px;
      background-color: #FF9A33;
      margin: 1px 5px;
      position: relative;
  }

  .DPE_F:after {
      content: "";
      /* required */
      position: absolute;
      /* takes the 'cap' out off flow */
      top: 0;
      /* stick it to top edge of the sign */
      left: 100%;
      /* push it way overto the right*/
      height: 0;
      /* we're doing this with borders remember */
      width: 0;
      border-width: 15px;
      border-style: solid;
      border-color: #FF9A33;
      /* same as bg of our rectangle */
      /* now we make some of theborders disappear*/
      border-top-color: transparent;
      border-bottom-color: transparent;
      border-right-color: transparent;
  }

  .DPE_G:before {
      font-weight: bold;
      padding-top: 5px;
      margin-left: 10px;
      content: ">450";
      text-align: center;
      vertical-align: middle;
  }

  .DPE_G {
      // >450
      width: 75%;
      height: 30px;
      background-color: #FF0000;
      margin: 1px 5px;
      position: relative;
  }

  .DPE_G:after {
      content: "";
      /* required */
      position: absolute;
      /* takes the 'cap' out off flow */
      top: 0;
      /* stick it to top edge of the sign */
      left: 100%;
      /* push it way overto the right*/
      height: 0;
      /* we're doing this with borders remember */
      width: 0;
      border-width: 15px;
      border-style: solid;
      border-color: #FF0000;
      /* same as bg of our rectangle */
      /* now we make some of theborders disappear*/
      border-top-color: transparent;
      border-bottom-color: transparent;
      border-right-color: transparent;
  }

  .txtright {
      float: right;
      vertical-align: middle;
      margin-right: 10px;
  }

  .txtrightreducesize {
      float: right;
      // border: 1px solid black;
      // vertical-align: middle;
      font-size: 0.8em;
      position: absolute;
      right: 10px;
      bottom: 150px;
  }

  .txtleft {
      float: left;
      vertical-align: middle;
      margin-left: 10px;
      font-weight: bold;
  }



  .item img {
      max-height: 300px;
      margin: auto;
  }

  .carousel {
      -webkit-box-shadow: 2px 2px 2px #D3D3D3;
      -moz-box-shadow: 2px 2px 2px #D3D3D3;
      background: #D3D3D3;
      background: -webkit-gradient(linear, 0 0, 0 bottom, from(#D3D3D3), to(#FBFAF4));
      background: -webkit-linear-gradient(#F1EEDD, #FBFAF4);
      background: -moz-linear-gradient(#F1EEDD, #FBFAF4);
      background: -ms-linear-gradient(#F1EEDD, #FBFAF4);
      background: -o-linear-gradient(#F1EEDD, #FBFAF4);
      background: linear-gradient(#F1EEDD, #FBFAF4);
  }

  .aib-accroche {
      text-align: center;
      //font-family: 'Helvetica';
      font-family: 'Kalam';
      font-size: 1.4em;
      color: #AC0000;
      // text-shadow: 2px 2px 0px #333333;
      vertical-align: middle;
  }

  .scrollToTop {
      color: red !important;
      width: 55px !important;
      height: 60px !important;
      padding: 10px !important;
      text-align: center !important;
      background: whiteSmoke !important;
      font-weight: bold !important;
      color: #444 !important;
      text-decoration: none !important;
      position: fixed !important;

      top: 700px !important;
      right: 100px !important;
      //display: none !important;
      display: block !important;

      //background-image: url('../images/arrowAibUp1.png') no-repeat 0px 20px !important;
      background:url('../images/arrowAibUp1.png') no-repeat 0px 20px !important;

  }

  .scrollToTop:hover {
      text-decoration: none;
  }

  .fonttelaib {
      font-family: 'Impact';
      text-align: center;
      font-size: 1.6em;
      background: #AC0000;
      color: white;
      word-spacing: 0.2em;
  }

  .fontlogodroiteaib {
      font-family: 'Impact';
      text-align: center;
      font-size: 1.2em;
      color: black;
      word-spacing: 0.3em;
  }

  .fontlogodroiteaibNormal {
      font-family: 'Verdana';
      text-align: center;
      color: black;
  }

  .fontlogodroiteaibNormal.bold {
      font-family: 'Verdana';
      text-align: center;
      color: black;
      font-weight: bold;
  }

  .fontlogodroiteaibNormal:a {
      text-decoration: none !important;
  }

  .slideshowhome {
      box-shadow: -4px 10px 8px #333333;
      -webkit-box-shadow: -4px 10px 8px #333333;
      -moz-box-shadow: -4px 10px 8px #333333;
  }

  div.cycle-overlay {
      font-weight: bold;
      color: #ffffff;
      //background: #AC0000;
      //background-color: rgba(0,0,0,.5);
      background-color: #AC0000;
      opacity: 0.75;
  }

  #photoacceuil a {
      color: White;
      text-decoration: none;
  }

  .aib-center-black {
      text-align: center;
      font-family: 'Helvetica';
      font-size: 1.1em;
  }

  .navsoulignaib {
      border-bottom: 5px solid #AC0000;
  }

  .addDPE {
     

      width: 70px;
                
      color: white;
      
      font-weight: bold;
      text-align: right;

  }
  .coverDpeGesImg{
      
      background-size: cover;
      background-repeat: no-repeat;

  }

  .fontmoins{
      font-size: 0.54em;

      // font-weight: bold;
  }
  .fontplus{
      font-size: 1.5em;

      font-weight: bold;
  }
  .reducefont {
      font-style: italic;
      font-size: 0.875em;
      margin-top: 4px;
  }
  .debugDPE{
      border-style: solid;
      border-width: 1px;

  }
  .contenant {
      position: relative;
      text-align: center;
      // border-style: solid;
      // border-width: 1px;
      // border-color: red;



  }

  .txtVertical{
      transform: rotate(270deg);
      font-size: 0.8em;
      text-align: center;
  }

  .dpeVierge{
      background-size: contain;
      font-weight: bold;
      font-size: 1.8em;
      transform: translate(15%, 100%) rotate(-25deg);
  }

  .dpetable td {
      
      margin-left: 10px;
      margin-right: 10px;
      // border-style: solid;
      // border-width: 1px;
      // border-color: black;
      font-weight: bold;
      display:inline-table;
      

  }
  .dpetable{
      // border-style: solid;
      // border-width: 1px;
      // border-color: red;
      overflow: hidden;
  }    
  .contenantDPE {
      position: relative;
      
      // border-style: solid;
      // border-width: 1px;
      // border-color: red;



  }
  .coverTdF{
      position: absolute;
      top: 0%;
      left: 0%;
      transform: translate(0%, -55%);


  }
  .coverTdG{

      position: absolute;
      top: 0%;
      left: 0%;
      transform: translate(0%, -75%);


  }
  .resetTd{
      position: relative;
  }



  .tdFillSpace{
      width: 112px;
  }
  .superposeTxt{
      position: absolute;
      top: 0%;
      left: 0%;
      transform: translate(25%, 0%);
      font-weight: bold;
  }

  .superposeTxtRight{
      position: absolute;
      top: 0%;
      left: 0%;
      transform: translate(138%, 0%);
      font-weight: bold;
  }


  .addDPEbefore{
      width: 70px;
      color: white;
      font-weight: bold;
      text-align: right;
  }



  .textbien {
      color: #AC0000;
      font-family: arial;
      font-weight: bold;
      font-size: large;
      display: block;
      margin-top: 20px;
      //  text-decoration:underline;
  }

  hr {
      border: 0;
      //border-top: 1px dashed #8c8c8c;
      border-top: 1px solid #AC0000;
      text-align: center;
  }
  /*hr:after {
content: '\002702';
display: inline-block;
position: relative;
top: -13px;
padding: 0 3px;
background: #fff;
color: #8c8c8c;
font-size: 18px;
}
*/

  .large200 {
      width: 200px;
  }

  ul.leaders {
      /*    max-width: 37em;*/
      padding: 0;
      overflow-x: hidden;
      list-style: none;
      li:before {
          float: left;
          width: 0;
          white-space: nowrap;
          color: black;
          //content: ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ";
      }
      span:first-child {
          /* font-weight: 600;*/
          padding-right: 0.33em;
          //background: #F1EEDD;
      }
      span+span {
          float: right;
          padding-left: 0.33em;
        //  background: #F1EEDD;
      }
  }